import React from 'react'

function NotFound() {
  return (
    <div className='h-100 w-100 d-flex justify-content-center flex-column align-items-center'>
            <h1>404</h1>
        <p>Page Not Found</p></div>
  )
}

export default NotFound